

.fatures-text{
    color: rgb(40,158,138);
   
}
.buttonF{
    border: 1px solid rgb(40,158,138) !important;
    color: rgb(40,158,138) !important;
  
  
}


.buttonF:hover{
  
    background-color: rgb(40,158,138) !important;
    color: #fff !important;
}