.card-b{
    border-radius: 5px 25px 5px 50px;

}
.card-container{
    background-color:#F1F6FB ;
    /* height: auto;
    display: flex;
    justify-content: center;
    align-items: center; */
}
.img-box{

    width: 5rem !important;
    border-radius: 50% !important;
    height: 4rem;
    margin-top: 1rem;


}
.card-text{
    font-size: 14px !important;
    line-height: 1.3rem;
    color: black !important;
}
.cardImg {
    width:90% !important;
    border-radius: 50% !important;

}
.card-box{
   width: 4rem;
}
.card-btn{
 background-color: rgb(40,158,138);
 color: #fff;
 border-radius: 10px;
 width: 50%;
 margin: 0 auto !important;
 margin-top: 1rem !important;

}
.card-bg:hover a{
    background-color:#fff !important;
    color: #000;

}

.card-bg{
    background-color: #fff !important;
    cursor: pointer;
  transition: 0.6s all ;
}
.card-bg:hover{
    background-color:rgb(40,158,138) !important;

}
.card-bg:hover p{
    color: #fff !important;

}
.card-bg:hover h6{
    color: #fff !important;

}
.card-bg:hover .icon{
    color: #fff !important;

}


