

  .bg-color{
    background-color: F7F7F7;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    border: none;
    border-bottom: 2px solid #289E8A;
    background: transparent;
    outline: none;
    width: 100%;
    padding: 1rem 0.4rem;
    
  }
  .btn-request{
   border: 1px solid #289E8A;
  }
  .btn-request:hover{
    background-color: #289E8A !important;
    color: #ffff;
  }
  

