.logo-slide img{
 height: 50px;
 margin: 0 50px;

}
.logo-slide{
    animation: 5s slide infinite linear;
    display: inline-block;   
}
.logo{
    overflow: hidden;
    padding: 30px 0px;
    background: #ffff;
    white-space: nowrap;
}
.logo:hover .logo-slide {
    animation-play-state: paused;

}
@keyframes slide{
    from{
        transform: translateX(0);
    }
    to{
        transform:translateX(-100%);
    }
}
