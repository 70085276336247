.card-h {
    transition: all 0.6s ease-in-out;
    cursor: pointer;
   height: 330px !important;
    
}

.card-h:hover {
    transform: translateY(-30px);
}
link{
    transition: all 0.3s ;

}
.card-h:hover .link {
    margin-left: 10px !important;
    

    
}


@media only screen and (max-width: 992px) {
    .card-h {
        transition: all 0.6s ease-in-out;
        cursor: pointer;
       height: auto !important;
    }    


}