.font-h1{
font-size: 7vw !important;

}
.font-h6{
    font-size: 5vw !important;
    color: #289E8A !important;
   margin-left: 4rem;

}
.font-h7{
    font-size: 5vw !important;
    margin-left: 13rem !important;

}
.btn-div{
    /* margin-left: 16rem; */
   display: flex !important;
  justify-content: center;
}
.textmargin{
    margin-left: 3rem;
}
@media only screen and (max-width: 767px) {
    .font-h1{
        font-size: 7.5vw !important;

        }
        .font-h6{
            font-size: 7.5vw !important;
           margin-left: 0rem !important;
        
        
        }
        .font-h7{
            font-size: 7.5vw !important;
            margin-left:  0rem !important;
            text-align: center;
          
        
        }
       
        .icon-div{
            margin-left: 0rem !important;
        }
        .textmargin{
            margin-left: 0rem;
        }
        .font-text{
            font-size: 15px !important;
        }
        .magin-top {
            display: flex !important;
            margin-top: 1rem;
            justify-content: center !important;
            
         
            }
            .flex{
               display: flex !important;
               justify-content: center !important;
               align-items: center;
               gap: 1rem;
               
            }


    
  }
  @media only screen and (min-width: 768px) and (max-width: 1111px){
    .magin-top {
       
    margin-top: 40px;
 
    }
    .font-h1{
        font-size: 5vw !important;

        }
        .font-h6{
            font-size: 3.5vw !important;
           margin-left: 0rem !important;
        
        
        }
        .font-h7{
            font-size: 3.5vw !important;
            margin-left:  0rem !important;
          
        
        }
        .btn-div{
            margin-left: 0rem !important;
        }
        .icon-div{
            margin-left: 0rem !important;
        }
        .font-text{
            font-size: 16px !important;
        }
        .textmargin{
            margin-left: 0rem;
        }


    }