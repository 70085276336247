
li{
   list-style: none;
   margin-right: 1rem;

}
.btn-demo{
    background-color: rgb(40,158,138) !important;
    color: #ffff !important;
    font-weight: 500!important;
    border: none !important;
}
.text-link:hover{
    color: #ffff !important;
   
    
}
.text-link,title{
    font-weight: 500!important;
    font-size: 20px;
  
}
#basic-nav-dropdown:hover{
    color: #ffff !important;
}
html {
    scroll-behavior: smooth !important;
  }

 
  
  @media only screen and (max-width: 600px) {
    .mob-bg {
      background-color: #A5DED4 !important;
      text-align: center !important;
      width: 100% !important;
 
      
    }
    
  }
  @media only screen and (max-width: 992px) {
    .nav-bg {
      background-color: #A5DED4 !important;
      text-align: center !important;
      width: 100% !important;
      
    }
    
  }
  
 
  @media only screen and (max-width: 1200px) {
    .btn-demo{
      padding: 10px !important;
      font-size: 12px !important;
    }

  }
 
